import type { SupportedCountries, SupportedLanguages } from '$lib/i18n/constants';
import { derived, get } from 'svelte/store';
import { page } from '$app/stores';
import Cookies from 'js-cookie';
import { goto } from '$app/navigation';
import { resolveRoute } from '$app/paths';
import { cache } from '$houdini';
import { dev } from '$app/environment';

type LocaleData = {
	locale: SupportedLanguages;
	country: SupportedCountries;
};

const MILLIS = 1000;
const DAY = 86400;
const YEAR = DAY * 365 * MILLIS;

const createLanguageStore = () => {
	const reactive = derived(page, ($page) => ({
		locale: $page.params?.locale,
		country: $page.params?.country
	}));

	const saveConfig = (data?: Partial<LocaleData>) => {
		const { locale, country } = get(reactive);
		Cookies.set(
			'sprenger_store_language',
			`${data?.locale ?? locale}-${data?.country ?? country}`,
			{
				secure: !dev,
				sameSite: 'strict',
				expires: new Date(Date.now() + YEAR)
			}
		);
		if ((data?.locale && data.locale !== locale) || (data?.country && data.country !== country)) {
			cache.reset();
		}
	};

	/**
	 * Returns the current route id including the search and hash params
	 */
	const getCurrentRouteId = () => {
		const $page = get(page);

		return `${$page.route.id}${$page.url.search}${$page.url.hash}`;
	};

	return {
		...reactive,
		saveConfig,
		changeLocale: (locale: SupportedLanguages) => {
			saveConfig({ locale });
			setTimeout(() => {
				goto(
					resolveRoute(getCurrentRouteId(), {
						...get(page).params,
						locale
					}),
					{
						replaceState: true,
						invalidateAll: true
					}
				);
			}, 200);
		},
		changeCountry: (country: SupportedCountries) => {
			saveConfig({ country });
			setTimeout(() => {
				goto(
					resolveRoute(getCurrentRouteId(), {
						...get(page).params,
						country
					}),
					{
						replaceState: true,
						invalidateAll: true
					}
				);
			}, 200);
		},
		hasInteracted: () => {
			return !!Cookies.get('sprenger_store_language');
		}
	};
};

export const languageStore = createLanguageStore();
